import { useCallback } from "react";
import { useIntl } from "react-intl";

import {
  missionSharedService,
  useDefaultAnalyticsData,
} from "@bees-grow-shared/services";

import { SCREEN_NAME } from "@/config/constants";
import trackTaskAgentCheckClicked from "@/utils/analytics/events/TrackTaskAgentCheckClicked";

import analytics from "../../../../../../../../../../config/typewriter";
import MarkAsDone from "../../MarkAsDone";

interface UseMarkAsDoneParams {
  missionKey: string;
  name: string;
  priority: number;
}

interface UseMarkAsDoneProps {
  handleMarkAsDoneClick: () => void;
  handleMarkAsNotDoneClick: () => void;
}

export function useMarkAsDone({
  missionKey,
  name,
  priority,
}: UseMarkAsDoneParams): UseMarkAsDoneProps {
  const missionService = missionSharedService();
  const defaultData = useDefaultAnalyticsData();
  const { formatMessage } = useIntl();

  const handleMarkAsDoneClick = useCallback(() => {
    missionService.setMissionMarkAsDone({
      missionKey,
      markAsDone: true,
    });
    trackTaskAgentCheckClicked({ task_name: name, task_priority: priority });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionKey, missionService]);

  const handleMarkAsNotDoneClick = useCallback(() => {
    missionService.setMissionMarkAsDone({
      missionKey,
      markAsDone: false,
    });
    analytics.buttonClicked({
      task_name: name,
      task_priority: priority,
      ...defaultData,
      screen_name: SCREEN_NAME,
      component_name: MarkAsDone.name,
      button_label: formatMessage({
        id: "TasksCarousel.TaskDrawer.MARK_AS_NOT_DONE",
      }),
      button_name: "MARK_AS_NOT_DONE",
      url: location.host,
      calling_method: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionKey, missionService]);

  return {
    handleMarkAsDoneClick,
    handleMarkAsNotDoneClick,
  };
}
