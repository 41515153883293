import MessageMap from "../i18n.d";

const es419: MessageMap = {
  TasksCarousel: {
    TITLE: "Tareas de llamadas",
    Empty: {
      DESCRIPTION: "No hay tareas asignadas a este POC.",
    },
    Error: {
      DESCRIPTION: "No es posible cargar las tareas de la llamada.",
      BUTTON: "Intentar de nuevo",
      GENERIC_ERROR: "Algo salió mal. Intenta de nuevo más tarde.",
    },
    TaskCard: {
      TOOLTIP_SPONSORED: "Patrocinada",
      Global: {
        Title: {
          TOPLINE: "Tarea de topline",
          MARKETPLACE: "Tarea de marketplace",
        },
        Description: {
          TOPLINE: "Recomendar productos basados en el pedido sugerido.",
          MARKETPLACE: "Fomentar la compra de productos del marketplace.",
        },
        Script: {
          TOPLINE:
            "Comprender la brecha entre el pedido actual y el pedido sugerido y recomendar productos para alcanzar los objetivos de ingresos.",
          MARKETPLACE:
            "Informar sobre los nuevos productos disponibles en el mercado y recomendarlos al PDV.",
        },
      },
      Local: {
        Description: {
          DEFAULT: "Tarea personalizada creada por los equipos de operaciones.",
        },
      },
    },
    TaskDrawer: {
      MARK_AS_DONE: "Marcar como hecho",
      MARK_AS_NOT_DONE: "Marcar como no hecho",
      Error: {
        RELOAD: "Recargar",
        GENERIC_ERROR:
          "No se puede cargar la información. Verifica tu conexión e intenta nuevamente.",
      },
      TOTAL_QUANTITY: "Cantidad total mínima: {quantity}.",
      MINIMUM_QUANTITY: "Cantidad mínima: {quantity}.",
    },
  },
  ProductSelection: {
    TITLE: "Productos",
    LAST_ORDER_BUTTON: "Mostrar los últimos pedidos",
    Empty: {
      EMPTY_PRODUCTS_MESSAGE:
        "Esta POC no tiene productos en {param}. Prueba con otra categoría.",
      EMPTY_PRODUCTS_SEARCH_MESSAGE:
        'No hay resultados para "{searchInput}" en el catálogo. Intente con otro término.',
    },
    Error: {
      DESCRIPTION: "No se pueden cargar productos. Intente recargar la página.",
      BUTTON: "Recargar",
    },
    Pagination: {
      LOADING: "Cargando",
      NO_MORE_ITEMS: "No hay más elementos para mostrar.",
      Error: {
        TRY_AGAIN: "Intentar de nuevo",
        ERROR_TEXT: "No se pueden cargar productos.",
      },
    },
    Modes: {
      SUGGESTED: "Pedido sugerido",
      PROMOTIONS: "Promociones",
      COMBOS: "Combos",
      RECOMMENDED: "Recomendados",
    },
    CategoryStatus: {
      Error: {
        DESCRIPTION: "No se pueden cargar las categorías.",
        BUTTON: "Intentar de nuevo",
      },
    },
    Search: {
      PLACEHOLDER: "Buscar por SKU o producto",
      Filter: {
        DESCRIPTION: "Filtrar por:",
        PROMOTIONS: "Promociones",
      },
      Details: {
        SUMMARY_TEXT: "Mostrando {quantityProducts} resultados para ",
      },
    },
    DiscountDetailsDrawer: {
      SteppedDiscount: {
        TITLE: "Descuento progresivo",
        SUB_TITLE:
          "Este producto tiene descuento progresivo. Más productos ofrecen más descuentos según la siguiente tabla.",
        DISCOUNT_MESSAGE:
          "Añade {quantity} y obtén un {discount}% de descuento",
        DISCOUNT: "Descuento",
        PRICE: "Precio",
      },
      RegularDiscount: {
        TITLE: "Descuento",
        DISCOUNT_MESSAGE: "Este producto tiene {discount}% de descuento.",
      },
    },
    FreeGoodDetailsDrawer: {
      TITLE: "Productos gratis",
      Options: {
        TITLE: "Opciones de productos gratis",
        QUANTITY_INFO:
          "Por cada {quantityDivisor} {productName}, Obtén {quantity} gratis.",
        CHECKOUT_INFO:
          "Los productos gratuitos se agregarán al carrito durante el proceso de pago.",
      },
      SteppedTable: {
        BUY: "Comprar",
        GET: "Obtener ",
      },
    },
  },
};

export default es419;
