import { getDefaultAnalyticsData } from "@bees-grow-shared/services";

import analytics from "@/config/typewriter";

interface TrackTaskDetailsClickedProps {
  task_name: string;
  task_priority: number;
}

export default function trackTaskDetailsClicked({
  task_name,
  task_priority,
}: TrackTaskDetailsClickedProps) {
  const defaultAnalyticsData = getDefaultAnalyticsData();
  analytics.buttonClicked({
    ...defaultAnalyticsData,
    task_name,
    task_priority,
    component_name: "poc_tasks",
    button_label: null,
    button_name: null,
    url: null,
    calling_method: "",
  });
}
